<template>
  <div class="wrap x-f" v-loading="loading" id="wrap">
    <!-- 左边树装选择 -->
    <categoryTreeCard
      categoryTreeTitle="门店信息"
      @allNodeIds="allNodeIds"
      :isChildLevelIds="true"
      :boxHeight="boxHeight"
      :boxInternalHeight="boxInternalHeight"
      :categoryTreeOptions="shopDeptTreeData"
    />
    <!-- 表格和搜索右边盒子 -->
    <div class="rightBox y-b">
      <!-- 表格搜索条件 -->
      <cardTitleCom cardTitle="查询" class="headGoodsSearch" id="search-card">
        <template slot="cardContent">
          <div class="navSearch">
            <!-- 高级搜索按钮 -->
            <seniorSearch
              v-model="queryParams"
              :getList="getList"
              :dropDownList="dropDownList"
              :isAdvancedSearch="false"
              :isBillDate="false"
              :isSearchInput="true"
              isSearchInputTitle="输入门店编码、名称、助记码"
            />
          </div>
        </template>
      </cardTitleCom>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom cardTitle="单据信息" class="headGoodsTable">
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <!-- 操作按钮 -->
            <operatingButton
              :getList="getList"
              :isRecycleBtn="true"
              :isDeleteBtn="false"
              :multiple="multiple"
              @handleAdd="handleAdd"
              @handleDelete="handleDelete"
              @handleRedoDelete="dialogOptions.show = true"
            />
            <div>
              <el-table
                ref="multipleTable"
                :data="tableData"
                tooltip-effect="dark"
                style="width: 100%"
                @selection-change="handleSelectionChange"
                border
                v-loading="loadingTable"
                @row-click="handleRowClick"
                :max-height="tableHeight"
                :height="tableHeight"
                :default-sort="{ prop: 'sortNo', order: 'ascending' }"
              >
                <el-table-column type="selection" width="50" align="center" />
                <el-table-column label="序号" type="index" width="80" align="center" />
                <el-table-column
                  align="center"
                  prop="shopNo"
                  label="门店编码"
                  key="shopNo"
                  show-overflow-tooltip
                  width="180"
                >
                  <template slot-scope="scope">
                    <el-link
                      type="primary"
                      @click.stop="handleDetail(scope.row)"
                      target="_blank"
                    >
                      {{ scope.row.shopNo }}
                    </el-link>
                  </template>
                </el-table-column>
                <el-table-column
                  label="门店名称"
                  align="center"
                  key="shopName"
                  prop="shopName"
                  show-overflow-tooltip
                  width="180"
                />
                <el-table-column
                  label="门店类型"
                  align="center"
                  key="shopTypeName"
                  prop="shopTypeName"
                  show-overflow-tooltip
                  width="100"
                />

                <el-table-column
                  label="门店面积"
                  align="center"
                  key="useArea"
                  prop="useArea"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="联系人"
                  align="center"
                  key="linkEmployeeName"
                  prop="linkEmployeeName"
                  show-overflow-tooltip
                  width="120"
                />
                <el-table-column
                  label="联系电话"
                  align="center"
                  key="linkEmployeeTel"
                  prop="linkEmployeeTel"
                  show-overflow-tooltip
                  width="140"
                />
                <el-table-column
                  label="门店地址"
                  align="center"
                  key="shopAddress"
                  prop="shopAddress"
                  width="200"
                  show-overflow-tooltip
                />
                <el-table-column
                  label="助记码"
                  align="center"
                  key="zjm"
                  prop="zjm"
                  width="120"
                  show-overflow-tooltip
                />
                <el-table-column
                  prop="sortNo"
                  align="center"
                  label="排序"
                  show-overflow-tooltip
                  :sortable="true"
                >
                </el-table-column>
                <el-table-column
                  label="备注"
                  align="center"
                  key="remark"
                  prop="remark"
                  min-width="120"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="修改人"
                  align="center"
                  key="updateBy"
                  prop="updateBy"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="修改时间"
                  align="center"
                  prop="updateTime"
                  width="160"
                  sortable
                  show-overflow-tooltip
                >
                </el-table-column>
                <el-table-column
                  label="创建人"
                  align="center"
                  key="createBy"
                  prop="createBy"
                  :show-overflow-tooltip="true"
                />
                <el-table-column
                  label="创建时间"
                  align="center"
                  prop="createTime"
                  width="160"
                  sortable
                  show-overflow-tooltip
                >
                </el-table-column>
              </el-table>
              <bottomPage
                v-model="queryParams"
                :getList="getList"
                :idsCom="ids"
                :totalCom="total"
                @selectAllCom="selectAll"
              />
            </div>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <!-- 添加或修改门店对话框 -->
    <c-dialog :title="title" :width="800" :showDialog.sync="open">
      <template #content>
        <el-form ref="form" :model="form" :rules="rules" label-width="100px">
          <div class="x-f">
            <el-form-item label="门店名称" prop="shopId">
              <treeselect
                :disabled="form.shopNo == '0000' && form.createTime"
                style="width: 250px"
                @input="inputSelect"
                v-model="form.shopId"
                :options="shopTreeOptions"
                :show-count="true"
                placeholder="请选择门店"
                :normalizer="normalizer"
                :disable-branch-nodes="true"
                size="mini"
              />
            </el-form-item>
            <el-form-item label="门店编码" prop="shopNo">
              <el-input
                :disabled="form.shopNo == '0000' && form.createTime"
                class="inputWidth"
                v-model="form.shopNo"
                placeholder="请输入门店编码"
                maxlength="20"
                size="mini"
              />
            </el-form-item>
          </div>
          <div class="x-f">
            <el-form-item label="门店类型" prop="shopType" v-if="shopType == 1">
              <el-select
                v-model="form.shopType"
                class="inputWidth"
                placeholder="门店类型"
                filterable
                clearable
                size="mini"
              >
                <el-option
                  v-for="item in dict.type.shop_type"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="门店类型" v-else>
              <el-input
                class="inputWidth"
                type="text"
                disabled
                placeholder="弱加盟店"
                size="mini"
              />
            </el-form-item>
            <el-form-item label="价格方案" prop="priceCaseId">
              <SelectRemote
                v-model="form.priceCaseId"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listPriceCase',
                    option: {
                      option: {
                        showItem: [
                          {
                            priceCaseId: form.priceCaseId,
                            priceCaseName: form.priceCaseName,
                          },
                        ],
                      },
                    },
                  }).option
                "
              />
            </el-form-item>
          </div>

          <div class="x-f">
            <el-form-item label="联系人" prop="linkEmployeeId">
              <SelectRemote
                v-model="form.linkEmployeeId"
                style="width: 250px"
                :option="
                  $select({
                    key: 'listEmployee',
                    option: {
                      option: {
                        showItem: [
                          {
                            employeeId: form.linkEmployeeId,
                            employeeName: form.linkEmployeeName,
                          },
                        ],
                      },
                    },
                  }).option
                "
              />
            </el-form-item>
            <el-form-item label="联系电话" prop="linkEmployeeTel">
              <el-input
                class="inputWidth"
                type="text"
                v-model="form.linkEmployeeTel"
                placeholder="请输入联系电话"
                maxlength="11"
                size="mini"
              />
            </el-form-item>
          </div>
          <div class="x-f">
            <el-form-item label="排序" prop="sortNo">
              <el-input-number
                size="mini"
                class="inputWidth"
                v-model="form.sortNo"
                placeholder="排序"
                controls-position="right"
                :min="1"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="门店面积" prop="useArea">
              <el-input
                class="inputWidth"
                size="mini"
                v-model="form.useArea"
                placeholder="请输入门店面积"
                maxlength="6"
              />
            </el-form-item>
          </div>
          <div class="x-f">
            <el-form-item label="门店地址" prop="regionId">
              <el-cascader
                ref="tree"
                size="mini"
                class="inputWidth"
                :options="provinceList"
                :props="defaultProp"
                v-model="form.regionId"
                maxlength="80"
                filterable
                clearable
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label="门店电话" prop="shopTel">
              <el-input
                class="inputWidth"
                size="mini"
                v-model="form.shopTel"
                placeholder="请输入门店电话"
              />
            </el-form-item>
          </div>
          <el-form-item label="详细地址" prop="regionAddress">
            <el-input
              size="mini"
              type="text"
              v-model="form.regionAddress"
              placeholder="详细地址"
              maxlength="80"
            />
          </el-form-item>

          <el-form-item label="备注" prop="remark">
            <el-input
              size="mini"
              v-model="form.remark"
              type="textarea"
              placeholder="备注长度介于 1 和 80 字符之间"
              maxlength="80"
              :autosize="{ minRows: 2, maxRows: 4 }"
            />
          </el-form-item>
        </el-form>
      </template>
      <template #buttonList>
        <el-button size="mini" class="marL10" @click="closeDia">取消</el-button>
        <el-button size="mini" type="primary" @click="saveAndAdd" class="marL10"
          >保存并新增</el-button
        >
        <el-button size="mini" type="primary" @click="submitForm" class="marL10"
          >保存</el-button
        >
      </template>
    </c-dialog>
    <!-- 回收站 -->
    <Dialog2 :options.sync="dialogOptions" @handleEvent="handleEvent" />
  </div>
</template>

<script>
import Dialog2 from "@/components/Dialog"; //回收站
import CDialog from "@/components/dialogTemplate/dialog/index.vue";
import { getToken } from "@/utils/auth";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { getAllRegion } from "@/api/goods/region";
import { treeselect as deptTreeSelect } from "@/api/system/dept";
import {
  listFranchiseShopInfo,
  addFranchiseShopInfo,
  delFranchiseShopInfo,
  getFranchiseShopInfoDetail,
  updateFranchiseShopInfo,
  restoreFranchiseShopInfo,
  getDeptTreeFranchise,
} from "@/api/joinClient/base/shop"; //加盟
import {
  listShopInfo,
  addShopInfo,
  delShopInfo,
  getShopInfoDetail,
  getDeptTree,
  updateShopInfo,
  restoreShopInfo,
} from "@/api/shop/base/shopInfo"; //门店
import cardTitleCom from "@/views/components/cardTitleCom"; //卡片标题
import categoryTreeCard from "@/views/components/categoryTreeCard"; //选择类别
import bottomPage from "@/views/components/bottomPage"; //底部分页
import seniorSearch from "@/views/components/seniorSearch"; //高级搜索
import operatingButton from "@/views/components/operatingButton"; //操作按钮
import SelectRemote from "@/components/tablePage/select/select-remote/index.vue";
export default {
  name: "shopBaseInfo",
  dicts: ["shop_type"],
  props: {
    shopType: {
      type: Number,
      default: 1,
    },
  },
  components: {
    Treeselect,
    CDialog,
    cardTitleCom,
    categoryTreeCard,
    bottomPage,
    seniorSearch,
    operatingButton,
    Dialog2,
    SelectRemote,
  },
  data() {
    return {
      dialogOptions: {
        type: "ShopInfo",
        show: false,
        title: "回收站",
        width: "75vw",
        data: {
          getListApi: this.shopType == 2 ? listFranchiseShopInfo : listShopInfo,
          restoreListApi: this.shopType == 2 ? restoreFranchiseShopInfo : restoreShopInfo,
          id: "shopId",
          search: [
            {
              type: "filters",
              isRestore: true,
              model: "",
              filters: [
                { filter: "query", label: "全部" },
                { filter: "shopNos", label: "门店编码" },
                { filter: "shopNames", label: "门店名称" },
                { filter: "zjms", label: "助记码" },
              ],
            },
            {
              type: "button",
              tip: "查询",
              btnType: "primary",
              click: "search",
              isRestore: true,
            },
          ],
          columns: [
            {
              prop: "shopNo",
              label: "门店编码",
              minWidth: 180,
            },
            {
              prop: "shopName",
              label: "门店名称",
              minWidth: 180,
            },
            {
              prop: "shopTypeName",
              label: "门店类型",
              minWidth: 100,
            },
            {
              prop: "useArea",
              label: "门店面积",
              minWidth: 100,
            },
            {
              prop: "linkEmployeeName",
              label: "联系人",
              minWidth: 120,
            },
            {
              prop: "linkEmployeeTel",
              label: "联系电话",
              minWidth: 120,
            },
            {
              prop: "shopAddress",
              label: "门店地址",
              minWidth: 120,
            },
            {
              prop: "zjm",
              label: "助记码",
              minWidth: 120,
            },
            {
              prop: "sortNo",
              label: "排序",
              minWidth: 120,
            },
            {
              prop: "remark",
              label: "备注",
              minWidth: 120,
            },
            {
              prop: "updateBy",
              label: "修改人",
              minWidth: 120,
            },
            {
              prop: "updateTime",
              label: "修改时间",
              minWidth: 155,
            },
            {
              prop: "createBy",
              label: "创建人",
              minWidth: 120,
            },
            {
              prop: "createBy",
              label: "创建时间",
              minWidth: 155,
            },
          ],
        },
      },
      //搜索框搜索的条件
      dropDownList: [
        { parameter: "query", name: "全部" },
        { parameter: "shopNos", name: "门店编码" },
        { parameter: "shopNames", name: "门店名称" },
        { parameter: "zjms", name: "助记码" },
      ],
      loadingTable: false, //表格加载
      defaultProp: {
        value: "id",
        label: "label",
        emitPath: false,
      },
      //add:新增;update：修改
      action: "",
      optionProps: {
        value: "id",
        label: "label",
        children: "children",
      },
      // 遮罩层
      loading: true,
      // 选中数组
      ids: [],
      //选中数组的名称
      shopName: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 分类表格数据
      tableData: [],
      // 部门机构树选项
      shopDeptTreeData: [],
      // 弹出层标题
      title: "",
      //门店选择禁用
      // shopSelectDisabled: true,
      // 区域选项
      shopTreeOptions: [],
      // 是否显示弹出层
      open: false,
      // 岗位选项
      postOptions: [],
      // 角色选项
      roleOptions: [],
      // 表单参数
      form: {
        province: undefined,
        city: undefined,
        zjm: null,
        sortNo: 1,
        remark: null,
        shopId: null,
        shopNo: null,
        shopName: null,
        storeId: null,
        regionId: null,
        linkEmployeeId: null,
        linkEmployeeTel: null,
        regionAddress: null,
        shopTel:null,
        deliverLineId: null,
        shopType: null,
        useArea: null,
        selectedCascaderOptions: [],
      },
      //门店类型
      shopTypeList: [],
      //全部行政地区
      provinceList: [],
      //市地区列表
      cityList: [],
      //区/县列表
      areaList: [],
      //商户员工
      // tenantEmployeeData: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      // 分类导入参数
      upload: {
        // 是否显示弹出层（分类导入）
        open: false,
        // 弹出层标题（分类导入）
        title: "",
        // 是否禁用上传
        isUploading: false,
        // 是否更新已经存在的分类数据
        updateSupport: 0,
        // 设置上传的请求头部
        headers: { Authorization: "Bearer " + getToken() },
        // 上传的地址
        url: process.env.VUE_APP_BASE_API + "/system/user/importData",
      },
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 15,
      },
      // 表单校验
      rules: {
        priceCaseId: [
          {
            required: true,
            message: "价格方案不能为空",
            trigger: ["blur", "change"],
          },
        ],
        // regionAddress: [
        //   {
        //     required: true,
        //     message: "详细地址不能为空",
        //     trigger: ["blur", "change"],
        //   },
        // ],
        regionId: [
          {
            required: true,
            message: "门店地址不能为空",
            trigger: ["blur", "change"],
          },
        ],
        linkEmployeeId: [
          {
            required: true,
            message: "联系人不能为空",
            trigger: ["blur", "change"],
          },
        ],
        shopType: [
          {
            required: true,
            message: "门店类型不能为空",
            trigger: ["blur", "change"],
          },
        ],
        useArea: [{ pattern: /^[0-9]{1,20}$/, message: "请输入1 ~ 20个数值类型的字符" }],
        shopId: [
          {
            required: true,
            message: "门店不能为空",
            trigger: "input",
          },
        ],
        linkEmployeeTel: [{ pattern: /^[0-9]{1,20}$/, message: "请输入数值类型的字符" }],
        shopNo: [
          {
            required: true,
            message: "门店编码不能为空",
            trigger: ["blur", "change"],
          },
          {
            pattern: /^[A-Za-z0-9]{1,20}$/,
            message: "请输入1 ~ 20个数值或英文的字符",
          },
        ],
      },
      vivwH: 0, //页面高度
      searchH: 0, //搜索框高度
      paginationH: 40,
    };
  },
  async created() {
    this.loading = true;
    await Promise.all([
      this.getList(),
      this.getRegion(),
      this.getDept(),
      this.getShopDeptTree(),
    ]);
    this.loading = false;
  },
  computed: {
    tableHeight() {
      return this.vivwH - this.paginationH - this.searchH - 140;
    },
    boxHeight() {
      return this.vivwH - 10;
    },
    boxInternalHeight() {
      return this.vivwH - 83;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    });
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    //还原刷新页面
    async handleEvent() {
      this.loading = true;
      await this.getList();
      await this.getShopDeptTree();
      this.loading = false;
    },
    handleResize() {
      this.vivwH = document.getElementById("wrap").clientHeight;
      this.searchH = document.getElementById("search-card").clientHeight;
    },
    //点击行选中
    handleRowClick(row) {
      // 获取表格对象
      // 切换行选中状态
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    // 下拉框校验方法
    inputSelect() {
      this.$refs.form.validateField("shopId");
    },
    //对话框关闭前的回调
    // handleClose (done) {
    //   if (
    //     this.form.shopNo ||
    //     this.form.shopId ||
    //     this.form.shopType ||
    //     this.form.useArea ||
    //     this.form.linkEmployeeId ||
    //     this.form.linkEmployeeTel ||
    //     this.form.regionId ||
    //     this.form.regionAddress ||
    //     this.form.remark
    //   ) {
    //     this.$confirm('确认关闭？')
    //       .then(_ => {
    //         done()
    //       })
    //       .catch(_ => {})
    //   } else {
    //     done()
    //   }
    // },
    //  修改\点击编码
    async handleDetail(row) {
      this.reset();
      this.action = "update";
      // this.shopSelectDisabled = true
      const shopId = row.shopId || this.ids[0];
      let urlType = this.shopType == 2 ? getFranchiseShopInfoDetail : getShopInfoDetail;
      await urlType(shopId).then((response) => {
        this.form = response.data;
        this.form.shopType = this.form.shopType + "";
        this.open = true;
        this.title = "修改门店信息";
      });
    },

    //获取机构树(关联门店)
    getShopDeptTree() {
      var query = {};
      query.allData = "1"; //获取全部机构(包含删除的)
      let urlType = this.shopType == 2 ? getDeptTreeFranchise : getDeptTree;
      urlType(query).then((response) => {
        this.shopDeptTreeData = response.data;
        console.log("shopDeptTreeData", this.shopDeptTreeData);
      });
    },
    // 获取商户员工
    // getTenantEmployeeData (employeeName) {
    //   var query = {}
    //   query.employeeName = employeeName
    //   getAllTenantEmployeeByName(query).then(response => {
    //     this.tenantEmployeeData = response.data
    //   })
    // },
    //获取机构
    getDept() {
      deptTreeSelect().then((response) => {
        this.shopTreeOptions = response.data;
      });
    },
    //获取全部行政地区事件
    getRegion() {
      getAllRegion().then((response) => {
        this.provinceList = response.data;
      });
    },
    //下拉树结构数据转换
    normalizer(node) {
      return {
        id: node.id,
        label: node.label,
        children: node.children,
      };
    },
    /** 查询门店列表 */
    async getList() {
      this.loadingTable = true;
      let urlType = this.shopType == 2 ? listFranchiseShopInfo : listShopInfo;
      await urlType(this.queryParams).then((response) => {
        this.tableData = response.rows;
        this.total = response.total;
        this.loadingTable = false;
      });
    },
    // 节点单击事件(左边的机构树)
    allNodeIds(value) {
      this.queryParams.deptIds = value;
      this.getList();
    },
    // 取消按钮
    cancel() {
      this.open = false;
      this.reset();
    },
    // 表单重置
    reset() {
      this.form = {
        province: undefined,
        city: undefined,
        zjm: null,
        sortNo: 1,
        remark: null,
        shopId: null,
        shopNo: null,
        shopName: null,
        storeId: null,
        regionId: null,
        linkEmployeeId: null,
        linkEmployeeTel: null,
        regionAddress: null,
        shopTel:null,
        deliverLineId: null,
        shopType: null,
        useArea: null,
        selectedCascaderOptions: [],
      };
      this.cityList = [];
      this.areaList = [];
      this.resetForm("form");
    },
    // 多选框选中数据
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.shopId);
      this.shopName = selection.map((item) => item.shopName);
      this.single = selection.length != 1;
      this.multiple = !selection.length;
    },
    /** 新增按钮操作 */
    handleAdd() {
      console.log("111111111");
      this.reset();
      this.open = true;
      this.title = "新增门店";
      this.action = "add";
      console.log("222222");
      // this.shopSelectDisabled = false
    },
    /** 提交按钮 */
    async submitForm() {
      let arr = [];
      let data = this.$refs.tree.getCheckedNodes()[0].pathLabels.toString();
      arr = data.replace(/[, ]/g, '');
      this.form.shopAddress = `${arr}${this.form.regionAddress}`
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          if (this.action == "update") {
            let urlType = this.shopType == 2 ? updateFranchiseShopInfo : updateShopInfo;
            await urlType(this.form).then((response) => {
              this.$modal.msgSuccess("修改成功");
              this.open = false;
              this.getList();
              this.getShopDeptTree();
            });
          } else {
            let urlType = this.shopType == 2 ? addFranchiseShopInfo : addShopInfo;
            await urlType(this.form).then((response) => {
              this.$modal.msgSuccess("新增成功");
              this.open = false;
              this.getList();
              this.getShopDeptTree();
            });
          }
        }
      });
    },
    // 保存并新增
    async saveAndAdd() {
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let urlType = this.shopType == 2 ? addFranchiseShopInfo : addShopInfo;
          await urlType(this.form).then((response) => {
            this.$modal.msgSuccess("保存新增成功");
            this.reset();
          });
        }
      });
    },
    //点击全选所有数据
    async selectAll(value) {
      if (value) {
        this.loadingTable = true;
        let urlType = this.shopType == 2 ? listFranchiseShopInfo : listShopInfo;
        const res = await urlType({
          ...this.queryParams,
          pageNum: 1,
          pageSize: this.total,
        });
        //重新渲染表格
        this.tableData = res.rows;
        //表格多选
        this.tableData.map((row) => {
          this.$nextTick(() => {
            this.$refs.multipleTable.toggleRowSelection(row, value);
          });
        });
        this.loadingTable = false;
      } else {
        //取消全选
        this.$nextTick(() => {
          this.$refs.multipleTable.clearSelection();
        });
        this.getList();
      }
    },
    /** 删除按钮操作 */
    handleDelete() {
      const shopIds = this.ids;
      this.$modal
        .confirm('是否确认删除门店名称为"' + this.shopName + '"的数据项？')
        .then(async () => {
          let urlType = this.shopType == 2 ? delFranchiseShopInfo : delShopInfo;
          await urlType(shopIds);
        })
        .then(() => {
          this.$modal.msgSuccess("删除成功");
          this.getList();
        });
    },
    /** 导出按钮操作 */
    handleExport() {
      this.download(
        "api/system/shop/base/info/export",
        {
          ...this.queryParams,
        },
        `shop_${new Date().getTime()}.xlsx`
      );
    },
    // 关闭弹窗
    closeDia() {
      this.open = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//整体盒子
.wrap {
  width: 100%;
  padding: 10px 10px 0 10px;
  background-color: #eaeaea;
  height: calc(100vh - 84px);
  // 右边
  .rightBox {
    height: calc(100vh - 98px);
    width: calc(100% - 354px); //商品搜索条件
    .headGoodsSearch {
      width: 100%;
      margin-bottom: 6px;
      // 搜索
      .navSearch {
        padding: 10px 10px 10px 20px;
        //筛选条件
        .searchCriteria {
          padding-top: 10px;
          .selectFrameUp {
            display: inline-block;
            margin-right: 15px;
          }
        }
      }
    }
    //商品表格卡片
    .headGoodsTable {
      height: calc(100% - 94px);
      margin-bottom: 0;
      flex: 1;
      .goodsMessage {
        margin: 6px 10px 0 10px;
      }
    }
  }
}

::v-deep .vue-treeselect__control {
  margin-top: 5px;
  height: 28px;
  line-height: 28px;
  width: 250px;
}
::v-deep .vue-treeselect__menu-container {
  height: 28px;
  line-height: 28px;
  width: 250px;
}
::v-deep .vue-treeselect__placeholder,
.vue-treeselect__single-value {
  line-height: 28px;
}
::v-deep .vue-treeselect__single-value {
  line-height: 28px;
}
</style>
